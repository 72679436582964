import React from "react";
import { Flex } from "rebass";
import PropTypes from "prop-types";
import Button from "../Button/Button";

const Pagination = ({
  page,
  countPage,
  nextButtonClick,
  previousButtonClick
}) => (
  <Flex justifyContent="space-between">
    <Button
      onClick={previousButtonClick}
      type="submit"
      name="submit subscribe form"
      variant="primary2"
      mt={3}
      mr={2}
      css={(page === 0 || page === 1) && { visibility: "hidden" }}
    >
      previous
    </Button>
    <Button
      onClick={nextButtonClick}
      type="submit"
      name="submit subscribe form"
      variant="primary2"
      mt={3}
      css={page === countPage && { visibility: "hidden" }}
    >
      next
    </Button>
  </Flex>
);

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  countPage: PropTypes.number.isRequired,
  nextButtonClick: PropTypes.func.isRequired,
  previousButtonClick: PropTypes.func.isRequired
};

export default Pagination;
