import React from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { graphql, navigate } from "gatsby";

import BlogCard from "../components/BlogCard";
import BlogLayout from "../components/BlogLayout";
import Pagination from "../components/Pagination";
import SEO from "../components/SEO";

import colors from "../utils/theme/colors";

const BlogPageTemplate = ({
  pageContext: { numPages, currentPage },
  data: {
    blogPageInfo: {
      frontmatter: { seoTitle, description, keywords, longTitle }
    },
    blogList: { edges: blogList },
    allBlogs: { edges: allBlogs },
    site: {
      siteMetadata: { siteUrl, titleTemplate, socialLinks }
    },
    bgMobile: {
      childImageSharp: { fluid: bgMobileFluid }
    }
  }
}) => {
  return (
    <>
      <SEO
        titleTemplate={titleTemplate}
        title={seoTitle}
        keywords={keywords}
        url={`${siteUrl}/blog`}
        description={description}
      />
      <BlogLayout
        title={seoTitle}
        socialTitle={seoTitle}
        allBlogs={allBlogs}
        socialLinks={socialLinks}
        slug={`${siteUrl}/blog/`}
        bgMobileFluid={bgMobileFluid}
        longTitle={longTitle}
      >
        <Flex
          width="100%"
          flexDirection="column"
          ml={[0, 0, "44px", "44px"]}
          mr={[0, 2, "44px", "44px"]}
        >
          {blogList.map(data => (
            <BlogCard
              key={data.node.id}
              mb={8}
              data={data}
              css={{ backgroundColor: colors.white }}
            />
          ))}
          <Pagination
            page={currentPage}
            nextButtonClick={() => {
              navigate(
                currentPage === 2 ? `/blog` : `/blog/page=${currentPage - 1}`
              );
            }}
            previousButtonClick={() => {
              navigate(`/blog/page=${currentPage + 1}`);
            }}
            countPage={numPages}
          />
        </Flex>
      </BlogLayout>
    </>
  );
};

export const query = graphql`
  query blog($skip: Int!, $limit: Int!) {
    bgMobile: file(relativePath: { eq: "images/bg-mobile.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    blogPageInfo: markdownRemark(frontmatter: { name: { eq: "blog" } }) {
      frontmatter {
        title
        longTitle
        seoTitle
        description
        keywords
      }
    }
    blogList: allMarkdownRemark(
      limit: $limit
      skip: $skip
      filter: { frontmatter: { collection_name: { eq: "blog-posts" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            category
            postImage {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            date
            title
            introtext
            author
            tags
          }
        }
      }
    }
    allBlogs: allMarkdownRemark(
      filter: { frontmatter: { collection_name: { eq: "blog-posts" } } }
      sort: { fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            category
            postImage {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            date
            title
            introtext
            author
            tags
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        titleTemplate
        socialLinks {
          icon
          url
        }
      }
    }
  }
`;

BlogPageTemplate.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.shape().isRequired
};

export default BlogPageTemplate;
