import * as React from "react";
import PropTypes from "prop-types";
import { Button as RebassButton } from "rebass";

import colors from "../../utils/theme/colors";

const Button = ({
  children,
  variant,
  bgColorOnHover,
  colorOnHover,
  ...props
}) => (
  <RebassButton
    variant={variant}
    {...props}
    css={`
      @media (min-width: 1280px) {
        transition: color 0.6s;
        ::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: ${bgColorOnHover};
          transform: translateX(calc(-100% - 1px));
          transition: transform 0.6s;
          z-index: -1;
        }
      }
      :hover {
        @media (min-width: 1280px) {
          color: ${colorOnHover};
          ::before {
            transform: translateX(0);
          }
        }
      }
    `}
  >
    {children}
  </RebassButton>
);

Button.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  colorOnHover: PropTypes.string,
  bgColorOnHover: PropTypes.string
};

Button.defaultProps = {
  children: null,
  variant: "primary",
  colorOnHover: colors.secondary2,
  bgColorOnHover: colors.white
};

export default Button;
